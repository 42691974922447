// CSS
import "./style.howitwork.css";

// images
import card1 from "../../assets/pngs/1.png"
import card2 from "../../assets/pngs/2.png"
import card3 from "../../assets/pngs/3.png"
import Right_logo from "../../assets/pngs/big icon cut.png"


const HowItWork: React.FC = () => {
    return (
        <section id="HowItWork-section" className="HowItWork-section">
            <div className="HowItWork-content-section">
                <div className="HowItWork-content-title">
                    <h5 className="tracking-in-expand">How it works</h5>
                    <h3>Transform Your Advertising Efforts Faster<br/>
                        Using Data Science and Analysis</h3>
                    <h3 className="HowItWork-content-steps">3 Easy Steps to Get Started:</h3>
                </div>
                <div className="HowItWork-card-section">
                    <div className="HowItWork-card">
                        <img className="HowItWork-card-image" src={card1} alt="" loading="lazy"/>
                        <p>import and analyze your data structure</p>
                    </div>
                    <div className="HowItWork-card">
                        <img className="HowItWork-card-image" src={card2} alt="" loading="lazy"/>
                        <p>Integrate you workflow with Omniverse's toolset</p>
                    </div>
                    <div className="HowItWork-card">
                        <img className="HowItWork-card-image" src={card3} alt="" loading="lazy"/>
                        <p>Level Up with Big Data, Data Science and AI</p>
                    </div>
                </div>
                <div className="HowItWork-description">
                    <p>
                        Our team at Omniverse is always at the ready and are eager to find new partners and new challenges.
                        We welcome your inquiries and are open to all questions.
                        Write to us today to schedule a first call to assess your needs and requirements and get the full strength
                        of the Omniverse ecosystem at your back to take your business and advertising efforts to the next level.
                    </p>
                </div>
            </div>
        </section>
    )
}
export default HowItWork;