import "./style.Intro.css"
import Intro_image from "../../assets/pngs/logo big.png"

const Intro:React.FC=()=>{
    return(
        <div id="Intro-section" className="Intro-section">
            <div className="Intro-contener">
                <div className="Intro-image-side tracking-in-expand">
                    <img src={Intro_image} alt="Data analysis"/>
                </div>
                <div className="Intro-text-side">
                    <h1 className="tracking-in-expand">All seeing All knowing</h1>
                    <h3 className="tracking-in-expand">omnipotent AdTech services suite.</h3>
                    <h5 className="tracking-in-expand">Monitor, secure, strategize and optimize all aspects of your multichannel<br/> advertising efforts and get the most bang for your buck.</h5>
                </div>
            </div>
        </div>
    )
}

export default Intro