import React from 'react';
import './App.css';
import { Outlet } from "react-router-dom";
import Menu from './Components/Menu/Menu';
import Intro from "./Components/Intro/intro"
import Sections from './Components/HowItWork/HowItWork';
import Home from './Pages/Home/Home';



function App() {
  return (
    <div className="App">
      
      <Home/>
    </div>
  );
}

export default App;
