import "./style.aboutus.css"
import Securty_icon from "../../assets/pngs/ic shield.png"
import Data_icon from "../../assets/pngs/ic analytics.png"
import ML_icon from "../../assets/pngs/ic artificial-intelligence.png"


const AboutUs: React.FC = () => {
    return (
        <section id="AboutUs-section" className="AboutUs-section">
            <div className="AboutUs-Content">
                <div className="AboutUs-section-title tracking-in-expand">
                    <h5 className="tracking-in-expand">About Omniverse</h5>
                    <h3>Optimizing focus, efficiency and<br/> cost effectiveness of advertising efforts</h3>
                </div>
                <div className="AboutUs-section-body tracking-in-expand">
                    <p>
                        At Omniverse, we provide a whole range of services and tools designed to help you better target your audience,<br />
                        trim the fat off your campaigns by identifying resources wasted and fraudulent ad consumption and increasing your<br/>
                        flexibility and reaction times by presenting you with everything you need to know when you need to know it. <br />
                        Utilizing cutting edge technologies and methodologies while maintaining a straight-forward, <br />
                        user-friendly interface, we ensure you can concentrate on the ``what`` instead of the ``how``. <br />
                    </p>
                </div>
                <div className="AboutUs-row-section">
                    <div className="AboutUs-row-tech tracking-in-expand">
                        <div className="AboutUs-row-icon">
                            <div className="row-icon-color text-align-end">
                                <h5>Advertising</h5>
                                <h1>Security</h1>
                            </div>
                            <div>
                                <img src={Securty_icon} alt="" />
                            </div>
                        </div>
                        <div className="AboutUs-row-text">
                            <p>
                                With so many bad-actors attempting to abuse and defraud the advertising market, you need the strongest ally you can find to have your back.<br />
                                With OmniSecure, bot mitigation, identity verification, ad farming prevention is fully automated and made easy to make sure your money and time are not wasted on those trying to bleed you dry.
                            </p>
                        </div>
                    </div>
                    <div dir="rtl" className="AboutUs-row-tech tracking-in-expand">
                        <div className="AboutUs-row-icon">
                            <div className="row-icon-color text-align-start">
                                <h1>Data</h1>
                                <h5>Science</h5>
                            </div>
                            <div>
                                <img src={Data_icon} alt="" />
                            </div>
                        </div>
                        <div className="AboutUs-row-text">
                            <p>
                                Our data scientists design state-of-the-art algorithms and tools aimed at processing your data and presenting you with the most up-to-date and most relevant information in an easily digested way so you can spend less time scanning tables and crunching numbers, and more time making decisions and advancing your business.
                            </p>
                        </div>
                    </div>
                    <div className="AboutUs-row-tech tracking-in-expand">
                        <div className="AboutUs-row-icon">
                            <div className="row-icon-color text-align-end">
                                <h1>Machine</h1>
                                <h5>Learning</h5>
                            </div>
                            <div>
                                <img src={ML_icon} alt="" />
                            </div>
                        </div>
                        <div className="AboutUs-row-text">
                            <p>
                                Constantly monitoring, reassessing and updating, our machine learning tools allow for fastest reaction times to changing scenarios and conditions. Sifting through data, making decisions, deriving insights and making predictive analyses has never been this easy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutUs;