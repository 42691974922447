import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../../Pages/Home/Home';
import AboutUs from '../../Pages/AboutUs/AboutUs'
import ContactUs from '../../Pages/ConnectUs/ConnectUs'
import Menu_logo from '../../assets/images/omni-logo.png'

import "./Style.Menu.css"

import Menu_icon from "../../assets/images/icons8-menu-ios-filled/icons8-menu-500.png"
const Menu: React.FC = () => {
    const [style, setStyle] = useState({ StyleName: "Menu-downlist", clicked: false });
    // let show = true
    const handleClick = () => {
        if (style.clicked == false) {
            setStyle({ StyleName: "Menu-downlist-new", clicked: !style.clicked })
        }
        else {
            setStyle({ StyleName: "Menu-downlist", clicked: !style.clicked })
        } 
    }
    return (
        <nav>
            <ul className='Menu-navbar'>
                <div className='Menu-Logo-place'>
                    <li><a href="#Intro-section">OMNIVERSE</a></li>
                </div>
                <div className='Menu-list-place'>
                    <li> <a href="#Intro-section">Home</a> </li>
                    <li> <a href="#AboutUs-section">About Omniverse</a> </li>
                    <li> <a href="#HowItWork-section">How it works</a> </li>
                    <li> <a href="#ContactUs-section">Contact Us</a> </li>
                </div>
                <div >
                    <img id='Menu-downlist' onClick={handleClick} className='Menu-icon' src={Menu_icon} alt="" />
                </div>
            </ul>
            <div className={style.StyleName}>
                <ul className='Dropdown-Menu'>
                    <li> <a href="#Intro-section" onClick={handleClick}>Home</a> </li>
                    <li> <a href="#AboutUs-section" onClick={handleClick}>About Omniverse</a> </li>
                    <li> <a href="#HowItWork-section" onClick={handleClick}>How it works</a> </li>
                    <li> <a href="#ContactUs-section" onClick={handleClick}>Contact Us</a> </li>
                </ul>
            </div>
        </nav>
    )
}
export default Menu;
