import "./style.connectus.css"

import phone from "../../assets/pngs/ic tel.png"
import location from "../../assets/pngs/ic location.png"
import email from "../../assets/pngs/ic email.png"
import logo_image from "../../assets/pngs/logo med.png"
import light_image from "../../assets/pngs/lens flare.png"
import { useEffect, useState } from "react"
// Mailer
import emailjs from 'emailjs-com';
import axios from 'axios'


const ConnectUs: React.FC = () => {
    const [ip, setIP] = useState({location:"",ipv:""});
    const [name, setName] = useState("");
    const [emailaddres, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP({location:res.data.country_name,ipv:res.data.IPv4});
    }

    useEffect(() => {
        getData()
    }, [])

    function sendEmail() {
        if (name !== "" && emailaddres !== "" && mobileNumber !== "") {
            emailjs.send("service_1gtd6kb", "template_zmsvy3g", {
                Fullname: name,
                Email: emailaddres,
                PhoneNumber: mobileNumber,
                Country: ip.location,
                IP: ip.ipv
            }, "Gl9oHMt81nMh3N3NZ").then(() => console.log("work")).catch((err) => console.log(err))
            console.log("function");
        }
    }

    return (
        <section id="ContactUs-section" className="ContactUs-section">
            <div></div>
            <div className="ContactUs-content tracking-in-expand">

                <h5>Connect Us</h5>
                <h3>Contact us today and a representative<br />
                    on our behalf will get back to you<br />
                    with all the details and benefits we can give you</h3>
            </div>
            <div className="Footer-and-ContactUs">
                <div className="ContactUs-Form tracking-in-expand">
                    <div className="ContactUs-input">
                        <input type="text" name="" onChange={e => { setName(e.target.value) }} id="" placeholder="Name" />
                    </div>
                    <div className="ContactUs-input">
                        <input type="email" name="" onChange={e => { setEmail(e.target.value) }} id="" placeholder="Email" />
                    </div>
                    <div className="ContactUs-input">
                        <input type="tel" name="" onChange={e => { setMobileNumber(e.target.value) }} id="" placeholder="Phone" />
                    </div>
                    <button onClick={sendEmail}>Send</button>
                </div>
                <footer className="Footer-section tracking-in-expand">
                    <div className="Footer-contact">
                        <div className="Footer-contact-detail">
                            <div className="go-right">
                                <img className="Footer-detail-image" src={location} alt="" />
                                <a>Marina Plaza, Al Marsa st, Dubai, UAE</a>
                            </div>
                            <div>
                                <img className="Footer-detail-image" src={email} alt="" />
                                <a href="mailto:info@omni-verse.ae">info@omni-verse.ae</a>
                            </div>
                            <div>
                                <img className="Footer-detail-image" src={phone} alt="" />
                                <a style={{ marginRight: '5px' }} href="tel:048728546">04 559 5644</a> /
                                <a style={{ marginLeft: '5px' }} href="tel:+971507298355">+971-50-7298355</a>
                            </div>
                            <div className="Footer-logo">
                                <img src={logo_image} alt="" />
                            </div>
                        </div>
                            <div className="Footer-Copyright">
                                <label htmlFor="">© Copyright 2022. Omniverse</label>
                            </div>
                    </div>

                </footer>
            </div>
        </section>
    )
}
export default ConnectUs;