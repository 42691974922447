
import Intro from "../../Components/Intro/intro";
import HowItWork from "../../Components/HowItWork/HowItWork"
import AboutUs from "../AboutUs/AboutUs";
import ConnectUs from "../ConnectUs/ConnectUs";
import Menu from "../../Components/Menu/Menu";

// CSS
import "./style.home.css"

// image
import Side_logo from "../../assets/pngs/big icon trans.png"
import Right_logo from "../../assets/pngs/big icon cut.png"


const Home: React.FC = () => {
    return (
        <>
            <Menu />
            <Intro />
            <img className="Right-side-logo" src={Side_logo} alt="" />
            <AboutUs />
            <img className="Left-side-logo" src={Right_logo} alt="" />
            <HowItWork />
            <ConnectUs />
        </>
    )
}
export default Home;